<template>
  <div class="page-navigation">
    <div class="nav-item nav-item-1" :class="{ active: page > 0 }">
      <div class="icon">
        <geo-dis />
      </div>
      <div class="text">{{ i18n['conversation-page-navigation'].tcGeographicDistribution }}</div>
    </div>
    <div class="sep" :class="{ active: page > 1 }"></div>
    <div class="nav-item nav-item-2" :class="{ active: page > 1 }">
      <div class="icon">
        <message-icon />
      </div>
      <div class="text">{{ i18n['conversation-page-navigation'].tcEvent }}</div>
    </div>
    <div class="sep" :class="{ active: page > 2 }"></div>
    <div class="nav-item nav-item-3" :class="{ active: page > 2 }">
      <div class="icon">
        <audience-icon />
      </div>
      <div class="text">{{ i18n['conversation-page-navigation'].tcAttendees }}</div>
    </div>
    <div class="sep" :class="{ active: page > 3 }"></div>
    <div class="nav-item nav-item-4" :class="{ active: page > 3 }">
      <div class="icon">
        <review-icon />
      </div>
      <div class="text">
        {{ i18n['conversation-page-navigation'].tcReview }} &amp;&nbsp;{{
          i18n['conversation-page-navigation'].tcSubmit
        }}
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import audienceIcon from '@/assets/svgs/audience-icon.vue'
import constantData from '@/json/data.json'
import GeoDis from '@/assets/svgs/geographic-distribution-icon.vue'
import messageIcon from '@/assets/svgs/message-icon.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import reviewIcon from '@/assets/svgs/review-icon.vue'

export default {
  name: 'conversation-page-navigation',
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'conversation-page-navigation': {
            tcAttendees: 'Attendees',
            tcEvent: 'Event',
            tcReview: 'Review',
            tcSubmit: 'Submit',
            tcGeographicDistribution: 'Geographic Distribution'
          }
        }
      }
    },
    page: {
      type: Number,
      default: 3
    }
  },
  mixins: [],
  data() {
    return {}
  },
  components: {
    audienceIcon,
    GeoDis,
    messageIcon,
    pageBody,
    reviewIcon
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.page-navigation {
  display: flex;
  align-items: center;
  padding: 30px 80px 100px;
  @include breakpoint(sm) {
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 30px 0 30px;
  }
  .nav-item {
    position: relative;
    @include breakpoint(sm) {
      width: 100%;
    }
    .icon {
      svg {
        fill: #8c8c8c !important;
      }
    }
    &.active {
      .icon {
        background-color: $secondary-color;
        svg {
          fill: #000000 !important;
        }
      }
      .text {
        color: #000;
      }
    }
  }
  .sep {
    height: 9px;
    flex: 1 1 auto;
    background-color: #ccc;
    &.active {
      background-color: $secondary-color;
    }
    @include breakpoint(sm) {
      position: relative;
      height: 30px;
      width: 9px;
      flex: 0 0 auto;
      left: 27px;
      transform: translateX(-50%);
    }
  }
  .icon {
    position: relative;
    height: 54px;
    width: 54px;
    flex: 0 0 auto;
    background-color: #ccc;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text {
    display: block;
    position: absolute;
    width: 140px;
    top: 100%;
    left: 50%;
    margin-top: 13px;
    transform: translateX(-50%);
    color: #8c8c8c;
    font-family: $open-sans;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.6px;
    line-height: 22px;
    text-align: center;
    @include breakpoint(sm) {
      width: calc(100% - 70px);
      left: 70px;
      top: 50%;
      margin-top: 0;
      transform: translateY(-50%);
      text-align: left;
    }
  }
}
</style>
